import { createSlice } from '@reduxjs/toolkit'

export const loading = createSlice({
  name: 'loading',
  initialState: { loading: 0 },
  reducers: {
    incrementLoading: (state, action) => {
      state.loading++
    },
    decrementLoading: (state, action) => {
      state.loading--
    },
    clearLoading: (state, action) => {
      state.loading = 0
    },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // }
  },
})

// Action creators are generated for each case reducer function
export const { incrementLoading, decrementLoading, clearLoading } = loading.actions

export default loading.reducer
