import React, { useCallback, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import AdminApp from './admin/AdminApp'
import App from './App'
import { Loading } from './shared'
import { ToastContainer } from 'react-toastify'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { systemServerConfigurationSuccessAction } from './reducers/systemConfiguration.reducer'
import { systemService } from './admin/services/system.service'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const Main = (props) => {
  const dispatch = useDispatch()

  const getServerTimeZone = useCallback(() => {
    systemService
      .getServerTimeZone()
      .then((res) => {
        if (res?.isSuccess) {
          dispatch(systemServerConfigurationSuccessAction(res?.data))
        }
      })
      .catch()
  }, [dispatch])
  useEffect(() => {
    getServerTimeZone()
  }, [getServerTimeZone])
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GoogleClientId}>
        <Loading />
        <Routes>
          <Route path="admin/*" element={<AdminApp />} />
          <Route path="" element={<App />} />
        </Routes>
        <ToastContainer />
      </GoogleOAuthProvider>
    </QueryClientProvider>
  )
}

export default Main
