import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import reportWebVitals from './reportWebVitals'
import { store, persistor } from './helpers'
import {} from 'moment/locale/vi'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
// import { AdminApp } from "./admin";
import './admin/scss/style.scss'
import './assets/scss/theme.scss'
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import moment from 'moment'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import 'react-toastify/dist/ReactToastify.min.css'
// import Main from './Main';

//Import default css react-datetime-picker
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
//End Import default css react-datetime-picker

import Main from './Main'
//End import default css coreui

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
moment.updateLocale('vi', {
  weekdays: ['Chủ nhật', 'Thứ 2', 'Thứ 3', 'Thứ 4', 'Thứ 5', 'Thứ 6', 'Thứ 7'],
})

const logLevels = {
  Trace: 0,
  Debug: 1,
  Information: 2,
  Warning: 3,
  Error: 4,
  Critical: 5,
  None: 6,
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Main />
        </React.Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)

reportWebVitals()
