import React from 'react'
// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { useSelector } from 'react-redux'

// Containers
// const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const AdminLayout = React.lazy(() => import('./layout/AdminLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page401 = React.lazy(() => import('./views/pages/401/Page401'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const AdminApp = (props) => {
  var authentication = useSelector((state) => state.authentication)
  if (!authentication.loggedIn) {
    return <Login />
  }

  let roles = authentication.userInfomation?.roles ?? []
  if (roles == 0) {
    return <Page401 />
  }

  return (
    <Routes>
      <Route path="/login" name="Login Page" element={<Login />} />
      <Route path="/register" name="Register Page" element={<Register />} />
      <Route path="/404" name="Page 404" element={<Page404 />} />
      <Route path="/500" name="Page 500" element={<Page500 />} />
      <Route path="*" name="Home" element={<AdminLayout />} />
    </Routes>
  )
}

export default AdminApp
