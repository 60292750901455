export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  INFOMATION_SUCCESS: 'USERS_INFOMATION_SUCCESS',

  LOGOUT: 'USERS_LOGOUT',
}

export const ROLE_TYPES = {
  ADMIN: 'ADMIN',
  MOD_OM: 'MOD-OM',
  MOD_CC: 'MOD-CC',
  MOD_AS: 'MOD-AS',
  MOD_TA: 'MOD-TA',
  MOD_AM: 'MOD-AM',
  MOD_PS: 'MOD-PS',
  SALES: 'SALES',
  MKT: 'MKT',
  OPERATOR: 'OPERATOR',
  TEACHER: 'TEACHER',
  PREMIUM: 'PREMIUM',
  FREEMIUM: 'FREEMIUM',
}

export const AvailableRoles = [
  { id: ROLE_TYPES.ADMIN, onlyEditBy: [ROLE_TYPES.ADMIN] },
  { id: ROLE_TYPES.MOD_OM, onlyEditBy: [ROLE_TYPES.ADMIN, ROLE_TYPES.MOD_OM, ROLE_TYPES.MOD_CC] },
  { id: ROLE_TYPES.MOD_CC, onlyEditBy: [ROLE_TYPES.ADMIN, ROLE_TYPES.MOD_OM, ROLE_TYPES.MOD_CC] },
  { id: ROLE_TYPES.MOD_AS },
  { id: ROLE_TYPES.MOD_TA },
  { id: ROLE_TYPES.MOD_AM },
  { id: ROLE_TYPES.MOD_PS },
  { id: ROLE_TYPES.SALES },
  { id: ROLE_TYPES.MKT },
  { id: ROLE_TYPES.OPERATOR },
  { id: ROLE_TYPES.TEACHER },
  { id: ROLE_TYPES.PREMIUM },
  { id: ROLE_TYPES.FREEMIUM },
]
