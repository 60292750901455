import { generatePath } from 'react-router-dom'

const learningPath = '/learning/:course/:courseId/:lesson?/:lessonId?/:tab?'
const courseDetailPath = '/course/:name/:id'
const courseCheckout = '/checkout/:name/:id'

export const UrlHelpers = {
  combine,
  createLearningUrl,
  createCourseDetailUrl,
  toQueryString,
  toQueryArray,
  arrayObjectToQueryString,
  learningPath: learningPath,
  courseDetailPath: courseDetailPath,
  courseCheckout: courseCheckout,
  isAbsoluteUrl,
  combineFormatting,
}

function combine(...urls) {
  if (urls === null || urls === undefined || urls.length === 0) return ''
  let result = urls.map((p) => (!p ? '' : p.replace(/(^[/])|([/]$)/g, ''))).join('/')
  return result
}

function combineFormatting(url, ...args) {
  url = url.replace(/{[0-9]+}/g, (x) => {
    let i = x.replace('{', '').replace('}', '')
    return args[i]
  })
  return url
}

function createLearningUrl(courseSlug, courseId, lessonSlug, lessonId, tab) {
  let path = generatePath(learningPath, {
    course: courseSlug,
    courseId: courseId,
    lesson: lessonSlug,
    lessonId: lessonId,
  })
  if (tab) path = UrlHelpers.combine(path, '?tab=' + tab)

  return path
}

function createCourseDetailUrl(courseSlug, courseId) {
  let path = generatePath(courseDetailPath, {
    name: courseSlug,
    id: courseId,
  })

  return path
}

function toQueryString(obj) {
  var str = []
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
    }
  return str.join('&')
}

function toQueryArray(parameterName, items) {
  var str = []
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    str.push(encodeURIComponent(parameterName) + '=' + encodeURIComponent(item))
  }
  return str.join('&')
}

function arrayObjectToQueryString(arr, fieldName) {
  var str = []
  for (let i = 0; i < arr.length; i++) {
    const element = arr[i]
    for (var p in element)
      if (element.hasOwnProperty(p)) {
        str.push(
          `${fieldName}[${i}].` + encodeURIComponent(p) + '=' + encodeURIComponent(element[p]),
        )
      }
  }
  return str.join('&')
}

function isAbsoluteUrl(url) {
  return url != null && url.startsWith('http')
}
