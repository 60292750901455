import { createSlice } from '@reduxjs/toolkit'
import { ArrayHelpers } from '../helpers'

const initialState = () => {
  return { popups: [] }
}

export const popupQueue = createSlice({
  name: 'popupQueue',
  initialState: initialState(),
  reducers: {
    addPopupAction: (state, action) => {
      var payload = action.payload
      if (Array.isArray(payload)) state['popups'].push(...payload)
      else state['popups'].push(payload)
    },
    removePopupAction: (state, action) => {
      var index = action.payload.popupIndex
      state['popups'].splice(index, 1)
    },
    clearPopupAction: (state, action) => {
      state['popups'] = []
    },
  },
})

// Action creators are generated for each case reducer function
export const { addPopupAction, removePopupAction, clearPopupAction } = popupQueue.actions

export default popupQueue.reducer
