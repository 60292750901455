import { createSlice } from '@reduxjs/toolkit'

const initialState = () => {
  return { configs: null, serverConfig: null }
}

export const systemConfiguration = createSlice({
  name: 'systemConfiguration',
  initialState: initialState(),
  reducers: {
    systemConfigurationSuccessAction: (state, action) => {
      state['configs'] = action.payload
    },
    systemServerConfigurationSuccessAction: (state, action) => {
      state['serverConfig'] = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { systemConfigurationSuccessAction, systemServerConfigurationSuccessAction } =
  systemConfiguration.actions

export default systemConfiguration.reducer
