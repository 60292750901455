import { confirmAlert } from 'react-confirm-alert'

export const modalActions = {
  show,
  clear,
}

function show(options) {
  if (!options) return

  let { title, ok, cancel, onOk, onCancel, hiddenCancel } = options
  let model = {
    title: '',
    message: title ?? 'Thông báo',
    buttons: [
      {
        label: ok ?? 'Đồng ý',
        onClick: () => onOk?.call(),
      },
      {
        label: cancel ?? 'Hủy bỏ',
        onClick: () => onCancel?.call(),
      },
    ],
  }

  if (hiddenCancel) {
    model.buttons.splice(1, 1)
  }

  confirmAlert(model)
}

function clear() {}
