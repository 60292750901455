import APICONFIGS from '../api-configs.json'
import { UrlHelpers, customFetch, authHeader } from '../helpers'

export const bannerService = {
  getAll,
}

function getAll(lessonId) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.Banners, lessonId)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}
