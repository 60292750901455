import { myCoursesConstants } from '../constants'
import { userService } from '../services'
import { UrlHelpers, store, authHeader } from '../helpers'
import { HubConnectionBuilder } from '@microsoft/signalr'
import {
  infomationSuccessAction,
  loginSuccessAction,
  logoutAction,
} from '../reducers/authentication.reducer'

export const userActions = {
  login,
  signup,
  loginGoogle,
  loginFacebook,
  getInfomation,
  checkLoggedIn,
  // checkLogin,
  logout,
  getMyCourses,
  clearMyCourses,
  // register,
  // getAll,
  // delete: _delete
}

function login(username, password, onSuccess) {
  const dispatch = store.dispatch

  userService.login(username, password).then(
    (res) => {
      if (res.isSuccess) {
        let data = res.data
        dispatch(loginSuccessAction({ userToken: data }))
        onSuccess?.call()
        getInfomation()
      }
    },
    (error) => {},
  )
}

function signup(email, displayName, password, onSuccess) {
  const dispatch = store.dispatch

  userService.signUp(email, displayName, password).then(
    (res) => {
      if (res.isSuccess) {
        let data = res.data

        dispatch(loginSuccessAction({ userToken: data }))
        getInfomation()
        onSuccess?.call()
      }
    },
    (error) => {},
  )
}

function loginGoogle(idToken) {
  const dispatch = store.dispatch

  userService.loginGoogle(idToken).then(
    (res) => {
      if (res.isSuccess) {
        let data = res.data

        dispatch(loginSuccessAction({ userToken: data }))
        getInfomation()
      }
    },
    (error) => {
      // dispatch(failure(error.toString()));
    },
  )
}

function loginFacebook(idToken) {
  const dispatch = store.dispatch

  userService.loginFacebook(idToken).then(
    (res) => {
      if (res.isSuccess) {
        let data = res.data

        dispatch(loginSuccessAction({ userToken: data }))
        getInfomation()
      }
    },
    (error) => {
      // dispatch(failure(error.toString()));
    },
  )
}

function getInfomation() {
  const dispatch = store.dispatch
  userService.getInfomarion().then(
    (userInfo) => {
      dispatch(infomationSuccessAction(userInfo.data))
    },
    (error) => {
      if (error.message == 401) {
        dispatch(logoutAction())
        clearMyCourses()
      }
    },
  )
}

function checkLoggedIn() {
  const dispatch = store.dispatch
  userService
    .getInfomarion()
    .then(
      (userInfo) => {
        dispatch(loginSuccessAction({ userToken: '' }))
        dispatch(infomationSuccessAction(userInfo.data))
      },
      (error) => {
        if (error.message == 401) {
          dispatch(logoutAction())
          clearMyCourses()
        }
      },
    )
    .catch((err) => {
      dispatch(logoutAction())
      clearMyCourses()
    })
}

// function checkLogin() {
//     return dispatch => {
//         let userToken = JSON.parse(localStorage.getItem('userToken'));
//         if (userToken != null) {
//             dispatch(success(userToken));
//             getInfomation()(dispatch);
//         }
//     };

//     function success(userToken) { return { type: userConstants.LOGIN_SUCCESS, userToken } }
// }

function getMyCourses() {
  const { dispatch } = store
  userService.getMyCourses().then((res) => {
    dispatch(success(res.data))
    // subcribeMyCourseChannel();
  })

  function success(courses) {
    return { type: myCoursesConstants.SUCCESS, courses }
  }
}

function subcribeMyCourseChannel() {
  if (!store.myCoursesChannel) {
    let userToken = store.getState().authentication.userToken
    var url = UrlHelpers.combine(process.env.REACT_APP_BASEURL, '/hubs/my-courses')
    let connection = new HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: () => userToken.token })
      .withAutomaticReconnect()
      .build()
    store.myCoursesChannel = connection
    connection.start()
    connection.on('new', function (message) {
      const { dispatch } = store
      var course = JSON.parse(message)
      dispatch(newCourse(course))
      // getMyCourses()(dispatch);
    })
    connection.on('update', function (message) {
      const { dispatch } = store
      var course = JSON.parse(message)
      dispatch(updateCourse(course))
      // getMyCourses()(dispatch);
    })
  }

  function newCourse(course) {
    return { type: myCoursesConstants.NEW, course }
  }
  function updateCourse(course) {
    return { type: myCoursesConstants.UPDATE, course }
  }
}

function clearMyCourses() {
  const { dispatch } = store
  dispatch({ type: myCoursesConstants.CLEAR })
  if (store.myCoursesChannel) {
    store.myCoursesChannel.stop()
    store.myCoursesChannel = null
  }
}

function logout() {
  store.dispatch(logoutAction())
  clearMyCourses()
}
