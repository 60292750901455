import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from '../reducers'
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'

const migrations = {
  0: (state) => {
    // migration clear out device state
    return {
      ...state,
      device: undefined,
    }
  },
  1: (state) => {
    // migration to keep only device state
    return {
      device: state.device,
    }
  },
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['modal', 'loading', 'alert', 'popupQueue', 'authentication'],
  migration: createMigrate(migrations, { debug: true }),
  stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

function getStore() {
  const loggerMiddleware = createLogger()
  var middlewares = [thunkMiddleware]
  if (process.env.NODE_ENV == 'development') middlewares.push(loggerMiddleware)
  return createStore(persistedReducer, applyMiddleware(...middlewares))
}

const store = getStore()
const persistor = persistStore(store)

export { store, persistor }
// export store
